import React, { useState, useEffect } from 'react'
import { Modal, ModalHeader, ModalFooter, ModalBody, Button, Col, FormGroup, Row } from 'reactstrap'
import { useSelector } from 'react-redux';
import "../../../assets/scss/plugins/extensions/toastr.scss"
import "react-toggle/style.css"
import "react-toastify/dist/ReactToastify.css"
import "../../../assets/scss/plugins/extensions/toastr.scss"
import API from '../../../providers/api';
import moment from 'moment';

export const ModalTimeZone = ({ showModal, setShowModal }) => {
    const toggleModal = () => {
        setShowModal(!showModal)
    }

    const toggleClose = () => setShowModal(!showModal)

return (
<Modal size='xl' isOpen={showModal} toggle={toggleClose}>
    <ModalHeader toggle={toggleClose}>
    Time zone
    </ModalHeader>
    <ModalBody>
        <iframe src='https://cambiohorario.com/zonas/usa/' style={{width:'100%', height:'500px'}}>
        </iframe>
    </ModalBody>
    <ModalFooter>
        <Button outline color="success" onClick={toggleModal}>Cerrar</Button>{" "}
    </ModalFooter>
</Modal>
)
}
