import React, { useState, useEffect } from 'react'
import { Modal, ModalHeader, ModalFooter, ModalBody, Button, Col, FormGroup, Row, Input } from 'reactstrap'
import { FaArrowAltCircleLeft, FaEye, FaTrash } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import "../../../assets/scss/plugins/extensions/toastr.scss"
import "react-toggle/style.css"
import "react-toastify/dist/ReactToastify.css"
import "../../../assets/scss/plugins/extensions/toastr.scss"
import API from '../../../providers/api';
import DataTable from 'react-data-table-component';
import moment from 'moment';
import { ToastContainer, toast } from 'react-toastify'

export const ModalNotePad = ({ showModal, setShowModal }) => {
    const user_id = useSelector(state => state.auth.login.state.data.user.verifyLogin.id);
    const [loading, setLoading] = useState(false)
    const [openForm, setOpenForm] = useState(false)
    const [viewNewButton, setViewNewButton] = useState(true)
    const [data, setData] = useState([])
    const [id, setId] = useState('')
    const [form, setForm] = useState('')
    const [formTitle, setFormTitle] = useState('')
    const [lastUpdate, setLastUpdate] = useState('Not register')
    const [timeOutSaveNotes, setTimeOutSaveNotes] = useState(false)

    const toggleModal = () => {
    setShowModal(!showModal)
    }

    useEffect(() => {
        listNotePad(user_id)
    }, [])

    const listNotePad = (user_id) => {
        API.listNotePad(user_id)
        .then(data => data.json())
        .then(dataJson => {
            if (dataJson.success === true) {
                setData(dataJson.data)
                setLoading(true)
            }else{
                setOpenForm(true)
                setViewNewButton(false)
            }
        })
        .catch((e) => {
            console.log("Error =>", e)
        })
    }
    const columns = [
        {
            name: "#",
            selector: (_, index) => data.length - index,
            sortable: true,
            width: '70px',
            wrap: true,
        },
        {
            name: "Date create",
            selector: row => moment(row.date_create).format("MM/DD/YYYY HH:mm:ss"),
            sortable: true,
            wrap: true,
        },
        {
            name: "Title",
            selector: row => row.title.toLocaleUpperCase(),
            sortable: true,
            width: '300px',
            wrap: true,
        },
        {
            name: "Action",
            sortable: true,
            width: '70px',
            wrap: true,
            cell: row => (
                <>
                    <div title='Details' className='button icon-position' style={{ cursor: "pointer" }} onClick={() => oppenform(JSON.stringify(row))}><FaEye size={20} /></div>
                </>
            )
        }
    ];

const save = async (onblur) => {
    console.log(formTitle, "formTitle");
    if(formTitle == "")
        return toast.warn("Title required")
    API.saveNotaPad(id, user_id, form, formTitle)
    .then(data => data.json())
    .then(dataJson => {
        if (dataJson.success === true) {
            setId(dataJson.id)
            toast.success(dataJson.message)
            if(!onblur){
                listNotePad(user_id)
                setOpenForm(false)
                setViewNewButton(true)
            }
            setLastUpdate(moment().format('DD-MM-YYYY HH:mm:ss'))
        }
    })
    .catch((e) => {
        console.log("Error =>", e)
    })
}

/* useEffect(() => {
    console.log(" *useEffect* ")
    return () => clearInterval(timeOutSaveNotes)
}, [formTitle, form, id]) */

const update = () => {console.log("update NP");save(true);}

const oppenform = (row) => {
    const data = JSON.parse(row)
    console.log(data, "row");

    setForm(data.text)
    setFormTitle(data.title)
    setOpenForm(true)
    setLoading(false)
    setId(data.id)
    setViewNewButton(false)

    /* setTimeOutSaveNotes(
        setInterval(update, 3000)
    ) */
}

const newNote = () => {
    setOpenForm(true)
    setLoading(false)
    setForm('')
    setFormTitle('')
    setId('')
    setViewNewButton(false)
}

const toBack = () => {
    setOpenForm(false)
    setLoading(true)
    setForm('')
    setFormTitle('')
    setViewNewButton(true)
    listNotePad(user_id)
    clearInterval(timeOutSaveNotes)
}

const customStyles = {
    headCells: {
        style: {
            paddingLeft: '8px', // override the cell padding for head cells
            paddingRight: '8px',
            backgroundColor: '#43B8E1',
            color: 'white'
        },
    }
};

const toggleClose = () => {setShowModal(!showModal); clearInterval(timeOutSaveNotes)}

return (
<Modal size='lg' isOpen={showModal} toggle={toggleClose}>
    <ModalHeader toggle={toggleClose}>
    My notes
    </ModalHeader>
    <form onSubmit={save}>
    <ModalBody>
        {
            loading &&
                <Row className='mt-2'>
                    <Col lg='12'>
                        <DataTable
                            columns={columns}
                            data={data}
                            pagination
                            customStyles={customStyles}
                        />
                    </Col>
                </Row>
        }
        {
            openForm &&
            <>
            <Row>
                <Col md="12" >
                    <FormGroup>
                        <FaArrowAltCircleLeft size={20} style={{ cursor: "pointer" }} title='To back' onClick={() => toBack()} />
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col md="12" >
                    <FormGroup>
                        <Input value={formTitle} onChange={e => { setFormTitle(e.target.value) }} placeholder="Title" />
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col md="12" >
                    <FormGroup>
                    <textarea rows={15} className="form-control" id="comment" value={form} onChange={e => { setForm(e.target.value) }} placeholder="Comment" onBlur={() => save(true)}></textarea>
                    </FormGroup>
                </Col>
            </Row>
            </>
        }
    </ModalBody>
    <ModalFooter>
            <Button color="info" onClick={newNote}>New</Button>{" "}
            {!viewNewButton && <Button color="success" onClick={() => save(false)}>Save</Button>}{" "}
            <Button outline color="success" onClick={toggleModal}>Closed</Button>{" "}
    </ModalFooter>
    </form>
    <ToastContainer />
</Modal>

)
}
