import React, { createContext, useContext, useState, useEffect } from 'react'
import API from '../providers/api'
const user = JSON.parse(sessionStorage.getItem('user'))
export const DataNavbarContext = createContext()

export const DataNavbarProvider = ({ children }) => {

    const [totalNavbarLeads, setTotalNavbarLeads] = useState(0)
    const [totalNavbarLeadsVicidial, setTotalNavbarLeadsVicidial] = useState(0)
    const [totalNavbarLeadsZoom, setTotalNavbarLeadsZoom] = useState(0)
    const [totalNavbarLeadsSold, setTotalNavbarLeadsSold] = useState(0)
    const [totalNavbarLeadsNoResponse, setTotalNavbarLeadsNoResponse] = useState(0)
    const [totalNavbarLeadsNotInterested, setTotalNavbarLeadsNotInterested] = useState(0)
    const [totalNavbarLeadsInterested, setTotalNavbarLeadsInterested] = useState(0)
    const [totalNavbarSalesHasAgent, setTotalNavbarSalesHasAgent] = useState(0)
    const [data, setData] = useState([])

    const getTotalNavbarLeads = async (id_status) => {
        let iduser = '-'
        if(user != null){
            if(user.profile == 104)
                iduser = user.id
        }
        try {
            API.filterTotalLeadDispositions(id_status, '-', '-', iduser)
            .then(data => data.json())
            .then(dataJson => {
                console.log(dataJson.data);
                if (dataJson.success === true) {
                    switch (id_status) {
                        case '326':
                            setTotalNavbarLeadsVicidial(dataJson.data.total)
                            break;
                        case '327':
                            setTotalNavbarLeadsZoom(dataJson.data.total)
                            break;
                        case '318':
                            setTotalNavbarLeadsSold(dataJson.data.total)
                            break;
                        case '315':
                            setTotalNavbarLeadsNoResponse(dataJson.data.total)
                            break;
                        case '316':
                            setTotalNavbarLeadsNotInterested(dataJson.data.total)
                            break;
                        case '317':
                            setTotalNavbarLeadsInterested(dataJson.data.total)
                            break;
                        default:
                            setTotalNavbarLeads(dataJson.data.total)
                            break;
                    }
                }
            })
        } catch (error) {
            console.log(error.message);
        }
    }

    const getTotalSalesHasAgent = async () => {
        try {
            API.getTotalSalesHasAgent()
            .then(data => data.json())
            .then(dataJson => {
                setTotalNavbarSalesHasAgent(dataJson.data.total)
            })
        } catch (error) {
            console.log(error.message);
        }
    }

    useEffect(() => {
        if(user != null){
            switch (user.profile) {
                case 238:
                    getTotalNavbarLeads('326')
                    getTotalNavbarLeads('327')
                    getTotalSalesHasAgent()
                    break;
                case 104:
                    getTotalNavbarLeads('326')
                    getTotalNavbarLeads('327')
                    break;
                case 251:
                    getTotalNavbarLeads('326')
                    getTotalNavbarLeads('327')
                    getTotalNavbarLeads('318')
                    getTotalNavbarLeads('315')
                    getTotalNavbarLeads('316')
                    getTotalNavbarLeads('317')
                    getTotalNavbarLeads('0')
                    getTotalSalesHasAgent()
                    break;
                case 325:
                    getTotalNavbarLeads('318')
                    getTotalNavbarLeads('315')
                    getTotalNavbarLeads('316')
                    getTotalNavbarLeads('317')
                    getTotalNavbarLeads('0')
                    break;
                case 219:
                    getTotalNavbarLeads('0')
                    break;
            }
        }
    }, [data])

    return (
        <DataNavbarContext.Provider value={{
            totalNavbarLeads, totalNavbarLeadsVicidial, totalNavbarLeadsZoom,
            totalNavbarLeadsSold, totalNavbarLeadsNoResponse, totalNavbarLeadsNotInterested, totalNavbarLeadsInterested,
            totalNavbarSalesHasAgent,
            data, setData }}>
            {children}
        </DataNavbarContext.Provider>
    )
}

export const useDataNavbarContext = () => {
    return useContext(DataNavbarContext)
}

